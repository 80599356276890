import { Workbox, messageSW } from 'workbox-window';

/**
 * differentiates between Safari and Chrome user agents on iOS:
 * chrome:
 *  Mozilla/5.0 (iPhone; U; CPU iPhone OS 5_1_1 like Mac OS X; en) AppleWebKit/534.46.0
 *  (KHTML, like Gecko) CriOS/19.0.1084.60 Mobile/9B206 Safari/7534.48.3
 * safari:
 *  Mozilla/5.0 (iPhone; U; CPU like Mac OS X; en) AppleWebKit/420+
 *  (KHTML, like Gecko) Version/3.0 Mobile/1A543 Safari/419.3
 */
/* eslint no-unused-vars: "off" */
function isIOSSafari() {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkitUa = !!ua.match(/WebKit/i);
  return iOS && webkitUa && !ua.match(/CriOS/i);
}

async function registerSW() {
  // check for updates once every 60 minutes
  const UPDATE_CYCLE_TIME = 60 * 60 * 1000;

  if ('serviceWorker' in navigator) {
    const windowWorker = new Workbox('/service-worker.js');

    window.addEventListener('load', () => {
      windowWorker.register().then((registration) => {
        // Registration was successful
        console.log('successful ServiceWorker registration with scope: ', registration.scope);

        registration.addEventListener('updatefound', () => {
          // prompt user to reload (skip waiting)
          const newWorker = registration.installing;
          if (newWorker) {
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installed' && navigator.serviceWorker.controller) {
                const app = document.querySelector('dd-app');
                app.showReloadDialog(() => {
                  messageSW(newWorker, { type: 'SKIP_WAITING' });
                });
              }
            });
          }
        });
      }, (err) => {
        // registration failed :(
        console.log('ServiceWorker registration failed: ', err);
      });
    });

    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      console.log('controllerchange event fired');
      if (refreshing) {
        return;
      }
      refreshing = true;
      window.location.reload();
    });

    setInterval(() => {
      try {
        windowWorker.update();
      } catch (err) {
        console.log('service worker update failed: ', err);
      }
    }, UPDATE_CYCLE_TIME);
  }
}

registerSW();
